<template>

    <div class="column is-6">
        <h1 class="title is-3">{{ $t('How to check version and configure web browser') }}</h1>
        <div class="tabs">
            <ul>
                <li :class="[ tab === 'ie' ? 'is-active' : '']"><a @click="tab = 'ie'">Microsoft Edge</a></li>
                <li :class="[ tab === 'firefox' ? 'is-active' : '']"><a @click="tab = 'firefox'">Mozilla Firefox</a></li>
                <li :class="[ tab === 'chrome' ? 'is-active' : '']"><a @click="tab = 'chrome'">Google Chrome</a></li>
                <li :class="[ tab === 'opera' ? 'is-active' : '']"><a @click="tab = 'opera'">Opera</a></li>
            </ul>
        </div>

        <transition name="slide-fade">
            <div v-if="tab === 'ie'">
                <p>{{ $t('It is recommended to use the latest version of Internet Explorer') }}.</p>
                <p>{{ $t('Click on Internet Explorer shortcut to start it') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/ie/` + lang + `/ie1.png`)">
                <div style="height:0.75rem;"></div>
                <p v-html="$t('As a result the browser version will be shown')"></p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/ie/` + lang + `/ie2.png`)">
<!--                <div style="height:0.75rem;"></div>-->
<!--                <p><span v-html="$t('The latest Internet Explorer version may be downloaded')"></span>.</p>-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <p>{{ $t('Make sure that JavaScript plug-in is enabled in IE browser for work with the website') }}.</p>-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <img :src="require(`@/assets/img/browsers/ie/` + lang + `/ie3.jpg`)">-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <p>{{ $t('Go to tab “Security” and click “Custom level”') }}.</p>-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <img :src="require(`@/assets/img/browsers/ie/` + lang + `/ie4.jpg`)">-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <p>{{ $t('Go to the section “Scripting” in “Settings”') }}.</p>-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <img :src="require(`@/assets/img/browsers/ie/` + lang + `/ie5.jpg`)">-->
<!--                <div style="height:0.75rem;"></div>-->
<!--                <p>{{ $t('Click “OK” to complete the process, and click “Yes” in the confirmation window') }}.</p>-->
            </div>
        </transition>

        <transition name="slide-fade">
            <div v-if="tab === 'firefox'">
                <p>{{ $t('It is recommended to use the latest version of Mozilla Firefox browser') }}.</p>
                <p>{{ $t('Click on Mozilla Firefox shortcut to start it') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/firefox/` + lang + `/firefox1.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('After entering “Help - About Firefox”') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/firefox/` + lang + `/firefox2.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('In some versions of browser you should manually click on the button ”Check for updates”') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/firefox/` + lang + `/firefox3.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('”Firefox is up to date.” will appear if you have the latest browser version') }}.</p>
            </div>
        </transition>

        <transition name="slide-fade">
            <div v-if="tab === 'chrome'">
                <p>{{ $t('It is recommended to use the latest version of Google Chrome browser') }}.</p>
                <p>{{ $t('Click on Google Chrome shortcut to start it') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/chrome/` + lang + `/chrome1.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('After entering “Help - About Google Chrome”') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/chrome/` + lang + `/chrome2.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('”Google Chrome is up to date.” will appear if you have the latest browser version') }}.</p>
            </div>
        </transition>

        <transition name="slide-fade">
            <div v-if="tab === 'opera'">
                <p>{{ $t('It is recommended to use the latest version of Opera browser') }}.</p>
                <p>{{ $t('Click on Opera shortcut to start it') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/opera/` + lang + `/opera1.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('After entering “Help - About Opera”') }}.</p>
                <div style="height:0.75rem;"></div>
                <img :src="require(`@/assets/img/browsers/opera/` + lang + `/opera2.jpg`)">
                <div style="height:0.75rem;"></div>
                <p>{{ $t('”Opera is up to date.” will appear if you have the latest browser version') }}.</p>
            </div>
        </transition>

    </div>

</template>

<script>
export default {
  name: 'SupportBrowsers',
  data () {
    return {
      tab: 'ie'
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
