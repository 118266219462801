import { render, staticRenderFns } from "./Browsers.vue?vue&type=template&id=bbb56d0a&scoped=true"
import script from "./Browsers.vue?vue&type=script&lang=js"
export * from "./Browsers.vue?vue&type=script&lang=js"
import style0 from "./Browsers.vue?vue&type=style&index=0&id=bbb56d0a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb56d0a",
  null
  
)

export default component.exports